@font-face {
    font-family: "IBMPlexSans";
    src: url("../../assets/fonts/IBMPlexSans-ExtraLight.ttf")
      format("TrueType");
    font-weight: 200;
  }
  
  @font-face {
    font-family: "IBMPlexSans";
    src: url("../../assets/fonts/IBMPlexSans-Thin.ttf") format("TrueType");
    font-weight: 300;
  }
  
  @font-face {
    font-family: "IBMPlexSans";
    src: url("../../assets/fonts/IBMPlexSans-Regular.ttf")
      format("TrueType");
    font-weight: 400;
  }
  
  @font-face {
    font-family: "IBMPlexSans";
    src: url("../../assets/fonts/IBMPlexSans-Medium.ttf") format("TrueType");
    font-weight: 500;
  }
  
  @font-face {
    font-family: "IBMPlexSans";
    src: url("../../assets/fonts/IBMPlexSans-SemiBold.ttf")
      format("TrueType");
    font-weight: 600;
  }
  
  @font-face {
    font-family: "IBMPlexSans";
    src: url("../../assets/fonts/IBMPlexSans-Bold.ttf") format("TrueType");
    font-weight: 700;
  }
  