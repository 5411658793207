html, 


body {
    height: 100vh;
    box-sizing: border-box;
    background: #08021C !important;
     ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
        height: 5px;
    }
     ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(170, 43, 209, 0.801);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
   
}
html{
  scroll-behavior: smooth;
}

body {
    #root {
        width: 100%;
        overflow: hidden;
    }
    font-family: "IBMPlexSans",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    SegoeUI,
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif;
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

a {
    text-decoration: none;
    color: inherit;
    &:hover {
        color: #f40074 !important;
    }
}

img,
svg {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

a {
    text-decoration: none !important;
}

p {
    margin-bottom: 0 !important;
}

input {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    outline: none;
    border: none;
    // Remove Arrows/Spinners
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    &[type="number"] {
        -moz-appearance: textfield;
    }
    &:focus-visible {
        outline: none;
    }
}

textarea {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    resize: none;
    &:focus {
        outline: none;
    }
}

button {
    border: none;
    outline: none;
    background: transparent;
    color: inherit;
    cursor: pointer;
    &:active,
    &:focus {
        outline: none;
    }
}

select {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
}

.MuiTreeItem-root {
    min-width: 100vw;
}

.swal2-backdrop-show {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    @media (min-width: 768px) {
        background-size: contain !important;
    }
}

.goog-logo-link {
    display: none !important;
}

.goog-te-gadget {
    color: transparent !important;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

body {
    top: 0px !important;
}

.swal2-footer {
    font-weight: bold;
    color: #079326;
}

// RADIO

@import url('https://fonts.googleapis.com/css?family=Raleway:300,400');

$clr--white:white;
$clr--black:black;
$clr--silver:silver;
$clr--highlight:magenta;
$clr--gray-light:whitesmoke;
$clr--gray-mid:gainsboro;
$clr--gray-dark:darkgray;

$hover-shadow--gray: $clr--gray-mid;
$def--anim-dur:250ms;
$def--anim-trans: ease-in-out;

$l--resp_display: 75rem;
$m--resp_display: 62rem;
$s--resp_display: 48rem;
$xs--resp_display: 36rem;

* {
  outline: none;
}

// body {
//   font-family: 'Raleway', sans-serif;
//   -webkit-font-smoothing: antialiased;
// }

#r8-balloon-radio-group-wrapper {

  
  :disabled{
    + label {
      color:$clr--gray-dark;
    }
  }
  
  label{


    font-weight:bold;
    color: white;
    
    
  }

  .r8-radio-float {
    &:not([disabled]){
    &:hover {
      background: $clr--gray-light;
      box-shadow: 0 0 0.25rem $clr--white;
      animation: stabilize 1 1s;
      
      &:checked {
        box-shadow: none;
        animation:unset !important;
      }
      
      &::before{
        /* Centered disc */
        border: .125rem solid $clr--highlight;
      }
      
      &::after {
        /* Hover shadow */
        opacity: 0.45;
      }
    }
  }
  }



  /* Radio Elements */
  .radio {
    position: relative;
    width: 2rem;
    height: 2rem;
    margin: .67rem;
    margin-top: 0;
    cursor: pointer;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transform-origin: center;
    border: .05rem solid $clr--gray-dark;
    border-radius: 50% 50% .2rem 50%;
    background: $clr--gray-mid;
    transition: all $def--anim-dur $def--anim-trans;
    transform: rotateZ(45deg) !important;
    &::before,
    &::after {
      position: absolute;
      content: '';
      border-radius: 50%;
    }

    &::before {
      /* Centered disc */
      top: .25rem;
      left: .25rem;
      width: 1.1rem;
      height: 1.1rem;
      background: $clr--white;
      border: .125rem solid $clr--gray-mid;
      box-shadow: 0 0 .05rem rgba($clr--black,.25);
      pointer-events: all;
      transition: all $def--anim-dur $def--anim-trans;
    }

    

    &:checked {
      background: $clr--gray-light;
      transform: scale(1.2);
      transition: background $def--anim-dur $def--anim-trans;
      
      &::before {
        width: 1.3rem;
        height: 1.3rem;
        background: $clr--highlight;
        border: .25rem solid rgba($clr--white, 0.5);
      }

      &:disabled {
        opacity: 0.40;
        background: $clr--gray-light;
      }
    }

    &:disabled {
      cursor: no-drop;
      opacity: 0.25;
      background: $clr--silver;
      animation: unset !important;
    }
  }
}



#r8-logo-coin {
  position: absolute;
  width: 3.5rem;
  height: 3.5rem;
  margin: 1rem;
  right: 1rem;
  bottom: 1rem;
  background-image:url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1251/R8-launcher-icon-light.svg');
    background-repeat:no-repeat;
    background-size:contain;
  background-position:0 0;
  
  &::before{
    position:relative;
    border-radius:50%;
    display:block;
    content:'';
    background-size:contain;
    background:$clr--highlight;
    margin:.5rem;
    width:calc( 90% - .6rem );
    height:calc( 90% - .6rem );
    z-index:-1;
  }
}

@media (min-width: $l--resp_display) {
  #r8-balloon-radio-group-wrapper {
    max-width: calc($l--resp_display - 3.75rem);
  }
}

@media (min-width: $m--resp_display) {
  #r8-balloon-radio-group-wrapper {
    max-width: calc($m--resp_display - 2rem);
  }
}

@media (min-width: $s--resp_display) {
  #r8-balloon-radio-group-wrapper {
    max-width: calc($s--resp_display - 3rem);
  }
}

@media (min-width: $xs--resp_display) {
  #r8-balloon-radio-group-wrapper {
    max-width: calc($xs--resp_display - 2.25rem);
  }
}

@keyframes stabilize {
  0% {
    transform: scale(0.8);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(0.9);
  }

  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}