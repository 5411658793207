.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1000px) {
    padding: 0 15px;
  }
}

@keyframes slide-up {
  0% {
    transform: translate(-50%, 100%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.main {
  background-color: #06142f;
}

.ant-collapse-header {
  align-items: center !important;
}

.ant-collapse-expand-icon {
  display: flex !important;
}

section {
  // max-width: 1920px !important;
  position: relative;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #b1086f;
  opacity: 1;
}
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
}

/* target all bullets */

.swiper-pagination-bullet {
  background-color: white;
  opacity: 1;
}

.swiper-slide-active {
  @media (max-width: 768px) {
    max-width: 700px !important;
    width: 100% !important;
  }
}
